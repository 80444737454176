import { format, startOfWeek, endOfWeek, startOfMonth, endOfMonth, eachDayOfInterval, getMonth, isToday, isSaturday, isSunday } from 'date-fns';
import { isHoliday } from 'japanese-holidays';
import PropTypes from 'prop-types';
import holidaysData from '../holidays.json';

const CalendarBody = (props) => {
  const { firstDayOfWeek, currentMonth } = props;
  const holidays = holidaysData.map(holiday => new Date(holiday));

  const renderWeeks = (month) => {
    const weekStart = startOfWeek(startOfMonth(month), { weekStartsOn: firstDayOfWeek });
    const weekEnd = endOfWeek(endOfMonth(month), { weekStartsOn: firstDayOfWeek });
    const dates = eachDayOfInterval({ start: weekStart, end: weekEnd });
    const weeks = [];

    for (let i = 0; i < dates.length; i += 7) {
      weeks.push(dates.slice(i, i + 7));
    }

    return weeks;
  };

  return (
    <div className="calendar">
      <div className="week-header grid grid-cols-7">
        {firstDayOfWeek === 0 && <div className="weekday">日</div>}
        <div className="weekday">月</div>
        <div className="weekday">火</div>
        <div className="weekday">水</div>
        <div className="weekday">木</div>
        <div className="weekday">金</div>
        <div className="weekday">土</div>
        {firstDayOfWeek === 1 && <div className="weekday">日</div>}
      </div>

      {renderWeeks(currentMonth).map((week, i) => (
        <div key={i} className="week grid grid-cols-7">
          {week.map((date, j) => {
            const sunday = isSunday(date);
            const saturday = isSaturday(date);
            const holiday = isHoliday(date, 'ja');
            const additionalHoliday = holidays.map(holiday => format(holiday, 'yyyy-MM-dd')).includes(format(date, 'yyyy-MM-dd'));
            const holidayAll = holiday || sunday || saturday || additionalHoliday;
            const outsidestartMonth = getMonth(date) !== getMonth(currentMonth);
            const className = `day ${outsidestartMonth ? 'outside' : ''} ${holidayAll ? 'holiday' : ''} ${sunday ? 'sunday' : ''} ${saturday ? 'saturday' : ''} ${isToday(date) ? 'today' : ''}`;

            return (
              <div key={j} className={className}>
                <span className="day-number">{format(date, 'd')}</span>
                {/* {holiday && <div className="comment">{holiday}</div>} */}
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

CalendarBody.propTypes = {
  month: PropTypes.object,
  firstDayOfWeek: PropTypes.number,
  dateFormat: PropTypes.string,
  currentMonth: PropTypes.instanceOf(Date)
};


export default CalendarBody;
