import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';

const Navigation = ({ toCurrentMonth, toPrevMonth, toNextMonth, currentMonth }) => {
  return (

    <div className="flex items-center gap-2">
      <button
        onClick={toCurrentMonth}
        className="border rounded px-3 sm:px-4 py-2 hover:bg-gray-100 transition-colors text-xs sm:text-sm leading-none">
        今月
      </button>

      <button
        disabled={currentMonth.getMonth() < 1}
        onClick={toPrevMonth}
        className="text-gray-600 disabled:text-gray-300 w-8 h-8 rounded-full hover:bg-gray-100 transition-colors grid place-items-center disabled:hover:bg-transparent"
      >
        <ChevronLeftIcon className="h-5 w-5 sm:h-6 sm:w-6" />
      </button>

      <button
        disabled={currentMonth.getMonth() > 10}
        onClick={toNextMonth}
        className="text-gray-600 disabled:text-gray-300 w-8 h-8 rounded-full hover:bg-gray-100 transition-colors grid place-items-center disabled:hover:bg-transparent"
      >
        <ChevronRightIcon className="h-5 w-5 sm:h-6 sm:w-6" />
      </button>
    </div>
  );
};

Navigation.propTypes = {
  toCurrentMonth: PropTypes.func,
  toNextMonth: PropTypes.func,
  toPrevMonth: PropTypes.func,
  currentMonth: PropTypes.instanceOf(Date)
};

export default Navigation;
