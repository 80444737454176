import { useEffect } from "react";
import Calendar from "./components/Calendar";

function Single() {
  const firstDayOfWeek = 1; // 0: 日曜日スタート, 1: 月曜日スタート
  const now = new Date(); // 今日を取得

  useEffect(() => {
    // ページの高さを取得し送信する
    const postIframeHeight = () => {
      const height = document.documentElement.offsetHeight;
      window.parent.postMessage({ height }, "*"); // https://example.comはiframeを埋め込むサイトのオリジンに置き換える
    };

    postIframeHeight();

    window.addEventListener("resize", () => {
      postIframeHeight();
    });
  });

  return (
    <div className="calendar-single">
      <Calendar
        month={now}
        firstDayOfWeek={firstDayOfWeek}
        dateFormat={"yyyy年 M月"}
      />
    </div>
  );
}

export default Single;
