// holidays.jsonをimportで読み込むパターン。holidays.jsonはsrcフォルダ直下に入れておく。
// buildなしで手動でjsonを差し替えたい場合は Calendar copy.jsを使う

import { useState } from 'react';
import { format, addMonths, subMonths } from 'date-fns';
import PropTypes from 'prop-types';
import Navigation from './Navigation';
import CalendarBody from './CalendarBody';
import Kyugyobi from './Kyugyobi';

const Calendar = (props) => {
  const { month, firstDayOfWeek, dateFormat } = props;

  // 状態を管理するためのReactのuseStateフックを使用します。
  // 初期値はpropsで渡された値です。
  const [currentMonth, setCurrentMonth] = useState(month);

  const toPrevMonth = () => {
    setCurrentMonth(value => subMonths(value, 1));
  };

  const toNextMonth = () => {
    setCurrentMonth(value => addMonths(value, 1));
  };

  const toCurrentMonth = () => {
    setCurrentMonth(new Date());
  };

  return (
    <div className="calendar-wrapper">
      <div className="mb-4 flex items-center justify-between">
        <div className="flex items-center gap-8">
          <h2 className="sm:text-lg md:text-xl text-gray-800 mr-auto">{format(currentMonth, dateFormat)}</h2>
          <Kyugyobi />
        </div>

        <Navigation
          toCurrentMonth={toCurrentMonth}
          toNextMonth={toNextMonth}
          toPrevMonth={toPrevMonth}
          currentMonth={currentMonth}
        />
      </div>

      <CalendarBody
        firstDayOfWeek={firstDayOfWeek}
        currentMonth={currentMonth}
      />
    </div>
  );
};

Calendar.propTypes = {
  month: PropTypes.object,
  firstDayOfWeek: PropTypes.number,
  dateFormat: PropTypes.string
};

export default Calendar;
